import { useQuery } from '@apollo/client';
import { IconArrowBarRight, IconArrowBarToLeft } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SplitPane, { Pane, SashContent } from 'split-pane-react';
import { ClassType, InputMaybe, SearchFeature, SearchFeatureType, SearchResponseObjectType, SearchResponsePageType } from '../__generated__/graphql';
import { FAVOURITE_CLASSES_QUERY } from '../api/queries/etimReleases';
import { SEARCH_UNIVERSAL_QUERY, SEARCH_UNIVERSAL_BY_QUOTATION_QUERY } from '../api/queries/searchRecords';
import { EtimClass } from '../components/quotation/overlays/EtimClass';
import SearchHeader from '../components/quotation/unifiedSearch/SearchHeader';
import FilterFeatures from '../components/search/filters/featuresFilter/FeaturesFilter';
import SearchComposeListWrapper from '../components/search/searchCompose/list/SearchComposeListWrapper';
import { useDebounceArray } from '../helpers/customHooks';
import { PaginationData, createPaginationData } from '../helpers/PaginationUtils';
import { useAppSelector } from '../helpers/reduxHooks';
import 'split-pane-react/esm/themes/default.css';
// eslint-disable-next-line import/order
import ReduxAlertWrapper from '../components/ReduxAlertWrapper';
import { Navbar } from '../layout';
import QuotationDetails from './QuotationDetails';
import './compose.css';

export default function Quotation() {
  const [sizes, setSizes] = useState<(string | number)[]>(['50%', '50%']);
  const isComposeMode = useAppSelector(state => state.quotation.isComposeMode);
  const selectedFeatures = useAppSelector(state => state.search.selectedFeatures);
  const selectedETIMClassId = useAppSelector(state => state.search.selectedETIMClassId);
  const searchInputValue = useAppSelector(state => state.search.searchInputValue);
  const QuotationContext = useAppSelector(state => state.search.QuotationContext);
  const debouncedSearchInput = useDebounceArray(searchInputValue, 200);
  const searchListPage = useAppSelector(state => state.search.searchListPage);
  const [isClassesOpen, setIsClassesOpen] = useState(true);
  const [isFeaturesOpen, setIsFeaturesOpen] = useState(true);
  const { t } = useTranslation();

  let searchFeatures: SearchFeature[] = [];
  if (selectedFeatures.length) {
    searchFeatures = selectedFeatures.map(feature => {
      return { code: feature.code, featureType: feature.featureType, userValue: feature.userValue };
    });
  }
  interface SearchVariablesType {
    page: number,
    etimClass: string,
    searchInput: InputMaybe<InputMaybe<string> | InputMaybe<string>[]>,
    searchFeatures: SearchFeature[],
    project: string | undefined,
    quotation: string | undefined,
    etimCatalog: string | undefined,
  }

  const searchVariables: SearchVariablesType = {
    page: searchListPage,
    etimClass: selectedETIMClassId,
    searchInput: debouncedSearchInput as InputMaybe<InputMaybe<string> | InputMaybe<string>[]>,
    searchFeatures: searchFeatures,
    project: undefined,
    quotation: undefined,
    etimCatalog: undefined,
  };
  
  if (QuotationContext && QuotationContext.searchProjectId && QuotationContext.searchQuotationId) {
    searchVariables.project = QuotationContext.searchProjectId;
    searchVariables.quotation = QuotationContext.searchQuotationId;
  }

  if (QuotationContext && QuotationContext.searchETIMCatalogId) {
    searchVariables.etimCatalog = QuotationContext.searchETIMCatalogId;
  }
  
  const optionalSearchValues = {
    project: QuotationContext?.searchProjectId,
    quotation: QuotationContext?.searchQuotationId,
    etimCatalog: QuotationContext?.searchETIMCatalogId,
  };
  
  const searchRecordsQuery = optionalSearchValues.project && optionalSearchValues.quotation
    ? SEARCH_UNIVERSAL_BY_QUOTATION_QUERY
    :  SEARCH_UNIVERSAL_QUERY;
  // Keep here network-only fetch policy in order to update Features filter
  // on Alphanumeric features DESELECT.  
  const {
    data: searchRecordsData,
    loading: searchRecordsLoading,
  } = useQuery(searchRecordsQuery, {
    variables: searchVariables,
    skip: searchRecordsQuery === SEARCH_UNIVERSAL_BY_QUOTATION_QUERY 
      ? !QuotationContext?.searchQuotationId 
      : !selectedETIMClassId,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (isComposeMode) {
      setSizes(['50%', '50%']);
    } else {
      setSizes(['0%', '100%']);
    }
  }, [isComposeMode]);

  const {
    data: classesData,
  } = useQuery(FAVOURITE_CLASSES_QUERY, {
    variables: {
    },
    skip: !isComposeMode,
  });
  
  const layoutCSS = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'auto',
  };

  //@ts-ignore
  const features: SearchFeatureType[] | undefined = searchRecordsData?.[Object.keys(searchRecordsData)[0]]?.response?.features;

  let pageData: SearchResponsePageType | undefined = undefined;

  if (searchRecordsData
    //@ts-ignore
    && searchRecordsData?.[Object.keys(searchRecordsData)[0]]
    //@ts-ignore
    && searchRecordsData?.[Object.keys(searchRecordsData)[0]].response
  ) {
    //@ts-ignore
    pageData = searchRecordsData?.[Object.keys(searchRecordsData)[0]].response as SearchResponsePageType;
  }

  return (
    <div className="flex flex-wrap min-h-screen">
      <div className="w-full">
        <Navbar isQuotationPage />
        <div className="h-[92%]">
          <ReduxAlertWrapper />
            <SplitPane
              sashRender={() => (
                <SashContent className='bg-cblue-500 z-40' >
                  {[
                    <div 
                      key={0}
                      className="w-0 h-0 absolute left-0 top-[74px] z-50
                        border-t-[24px] border-t-transparent
                        border-l-[10px] border-l-cblue-100
                        border-b-[24px] border-b-transparent"
                    />,
                  ]}
                </SashContent>
              )}
              split='vertical'
              sizes={sizes}
              onChange={setSizes}
              className='h-full '
              resizerSize={isComposeMode ? 10 : 0}        
            >
              <Pane minSize={10}>
                <div style={{ ...layoutCSS }}>
                  <div className="relative flex flex-col h-full bg-white shadow-xl overflow-y-auto w-full">
                    <div className="relative flex flex-col gap-4 w-full min-w-[900px] h-full">
                      <SearchHeader />
                      <div className='flex w-full bg-white overflow-y-clip grow'>
                        {isClassesOpen ?
                          <div className='flex flex-col'>
                            <div 
                              className="sticky top-0 z-20 flex items-center bg-cblue-100 border-t-2 border-b-2 border-cblue-500 pt-3 pb-3 mb-3 font-bold text-sm text-cgray-600"
                            >
                              <IconArrowBarToLeft className="w-6 h-6 ml-4 cursor-pointer" onClick={()=>setIsClassesOpen(false)} />
                              <div className="w-36 mt-0.5 mb-0.5 h-5 ml-2">{t('Favorite classes')}</div>
                            </div>
                            <div className="grow w-72 pl-2 flex flex-col gap-2 pr-2 border-r-2 border-cgray-300 h-full max-h-screen overflow-y-scroll overflow-x-clip">
                              {classesData && classesData.favouriteClasses && classesData.favouriteClasses.response
                                && (classesData.favouriteClasses.response.map(
                                  (option) => (<EtimClass key={option?.id} etimClass={option as ClassType} />))
                                )
                              }
                            </div>
                          </div>
                          :
                          <div onClick={()=>setIsClassesOpen(true)}>
                            <div className="sticky top-0 z-20 flex items-center bg-cblue-100 border-t-2 border-b-2 border-cblue-500 pt-3 pb-3 mb-3 font-bold text-sm text-cgray-600">
                              <IconArrowBarRight className="w-6 h-6 ml-2 cursor-pointer" />
                            </div>
                            <div 
                              className="grow w-12 flex flex-col justify-center gap-2 border-r-2 border-cgray-300 h-full max-h-screen overflow-y-scroll overflow-x-clip cursor-pointer"
                            >
                              <div className='-rotate-90 whitespace-nowrap uppercase'>{t('Favorite classes')}</div>
                            </div>
                          </div> 
                        }
                        {!!features?.length && 
                          isFeaturesOpen ?
                          <div className='flex flex-col'>
                            <div 
                              className="sticky top-0 z-20 flex items-center bg-cblue-100 border-t-2 border-b-2 border-cblue-500 pt-3 pb-3 mb-3 font-bold text-sm text-cgray-600"
                            >
                              <IconArrowBarToLeft className="w-6 h-6 ml-4 cursor-pointer" onClick={()=>setIsFeaturesOpen(false)} />
                              <div className="w-36 mt-0.5 mb-0.5 h-5 ml-2">{t('Default features')}</div>
                            </div>
                            <FilterFeatures
                              isLoading={searchRecordsLoading}
                              features={features}
                            />
                          </div>
                          : !!features?.length && 
                          <div onClick={()=>setIsFeaturesOpen(true)}>
                            <div className="sticky top-0 z-20 flex items-center bg-cblue-100 border-t-2 border-b-2 border-cblue-500 pt-3 pb-3 mb-3 font-bold text-sm text-cgray-600">
                              <IconArrowBarRight className="w-6 h-6 ml-2 cursor-pointer" />
                            </div>
                            <div 
                              className="grow w-12 flex flex-col justify-center gap-2 border-r-2 border-cgray-300 h-full max-h-screen overflow-y-scroll overflow-x-clip cursor-pointer"
                            >
                              <div className='-rotate-90 whitespace-nowrap uppercase'>{t('Default features')}</div>
                            </div>
                          </div>
                        }
                        <div className='grow relative overflow-y-scroll shrink-0'>
                          <SearchComposeListWrapper
                            objectsData={pageData?.objects as SearchResponseObjectType[]}
                            isLoading={searchRecordsLoading}
                            paginationData={createPaginationData(pageData as PaginationData)}
                            totalObjectsQuantity={pageData?.totalObjectsQuantity as number}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Pane>
              <Pane minSize={10}>
                <div style={{ ...layoutCSS } }>
                  <div className="grow flex flex-col h-full bg-white shadow-xl overflow-y-auto ml-px ">
                    <QuotationDetails />
                  </div>
                </div>
              </Pane>
            </SplitPane>
        </div>
      </div>
    </div>
  );
}
